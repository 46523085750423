import React, { Fragment } from 'react'
import { Row, Col, Slider, Button } from 'antd'
import type { SliderSingleProps } from 'antd'
import '../styles/UserInput.css'
import IconMan from '../assets/icons/man.svg'
import IconWoman from '../assets/icons/woman.svg'
import { styleButtonActive } from './Home'

const marks: SliderSingleProps['marks'] = {
  0: {
    style: {
      fontSize: '10px',
      width: '60px',
    },
    label: '0 bulan',
  },
  70: {
    style: {
      fontSize: '10px',
      width: '60px',
    },
    label: '70 tahun',
  },
}

const defaultAge = 25

export interface UserInputProps {
  gender: string
  age: number
  setAge: any
  setGender: any
  isShowResult: boolean
  onCountClick: any
}

export default function UserInput({
  gender,
  age,
  setAge,
  setGender,
  isShowResult,
  onCountClick,
}: UserInputProps) {
  return (
    <Fragment>
      <Row justify="center">
        <Col xl={10} lg={10} md={20} sm={20} xs={24}>
          <p className="center">Jenis kelamin tertanggung</p>
        </Col>
      </Row>
      <Row justify="center" gutter={{ xs: 10, sm: 16, md: 20, lg: 20 }}>
        <Col span={24} className="center">
          <Button
            className="buttonGender center"
            onClick={() => {
              setGender('M')
            }}
            style={gender === 'M' ? styleButtonActive : {}}
          >
            <img
              className={'iconGender'}
              src={IconMan}
              alt={'icon laki-laki'}
            />
          </Button>
          <Button
            className="buttonGender center"
            onClick={() => {
              setGender('F')
            }}
            style={gender === 'F' ? styleButtonActive : {}}
          >
            <img
              className={'iconGender'}
              src={IconWoman}
              alt={'icon perempuan'}
            />
          </Button>
        </Col>
      </Row>
      <Row justify="center">
        <Col>
          <p className="center">berumur</p>
        </Col>
      </Row>
      <Row justify="center">
        <Col xl={6} lg={10} md={14} sm={16} xs={24}>
          <Slider
            className="center"
            marks={marks}
            defaultValue={defaultAge}
            max={70}
            value={age}
            onChange={setAge}
          />
        </Col>
      </Row>
      <Row justify="center">
        <Col xl={10} lg={10} md={20} sm={20} xs={24} className="center">
          {!isShowResult && (
            <Button id="buttonCount" onClick={onCountClick}>
              Hitung premi sekarang
            </Button>
          )}
        </Col>
      </Row>
    </Fragment>
  )
}
