import { collection, getDocs } from 'firebase/firestore'
import { db } from "../firebaseInit"

export class InsurancePlan {
  planId: string
  bed: number
  description?: string
  limitBooster: number
  limitCoverage: number
  name: string
  subtitle?: string
  title: string
  periodUnit: string
  priceUnit: string

  constructor(snapshot: any) {
    this.planId = snapshot.plan_id
    this.bed = snapshot.bed
    this.description = snapshot.description
    this.limitBooster = snapshot.limit_booster
    this.limitCoverage = snapshot.limit_coverage
    this.name = snapshot.name
    this.subtitle = snapshot.subtitle
    this.title = snapshot.title
    this.periodUnit = 'bulan'
    this.priceUnit = 'Rp'
  }
}

export const insurancePlan: InsurancePlan[] = [
  {
    planId: 'bronzeb',
    bed: 2,
    limitBooster: 5,
    limitCoverage: 2,
    name: 'Bronze B',
    periodUnit: 'bulan',
    priceUnit: 'Rp',
    title: 'Indonesia',
  },
  {
    planId: 'bronzed',
    bed: 1,
    limitBooster: 7,
    limitCoverage: 3.5,
    name: 'Bronze D',
    periodUnit: 'bulan',
    priceUnit: 'Rp',
    title: 'Indonesia',
  },
  {
    planId: 'silverb',
    bed: 1,
    limitBooster: 10,
    limitCoverage: 6,
    name: 'Silver B',
    periodUnit: 'bulan',
    priceUnit: 'Rp',
    subtitle: '(kecuali SG, HK, JP)',
    title: 'Asia',
  },
  {
    planId: 'golda',
    bed: 1,
    limitBooster: 20,
    limitCoverage: 7.5,
    name: 'Gold A',
    periodUnit: 'bulan',
    priceUnit: 'Rp',
    title: 'ASIA',
  },
]

export async function getAllPlan() {
  const querySnapshot = await getDocs(collection(db, 'insurance_plan'))
  if (querySnapshot.empty) {
    console.log('No such collection!')
    return []
  } else {
    let data: InsurancePlan[] = []
    querySnapshot.forEach((doc) => {
      data.push(new InsurancePlan(doc.data()))
    })
    return data
  }
}
