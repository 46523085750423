const firebaseConfig = {
  apiKey: 'AIzaSyB6xkhWRoqcd1xz3i-yh9KfqQSJcRJaavE',
  authDomain: 'devakumala-e0a96.firebaseapp.com',
  projectId: 'devakumala-e0a96',
  storageBucket: 'devakumala-e0a96.appspot.com',
  messagingSenderId: '611207423251',
  appId: '1:611207423251:web:19305adafeafc8f4c9a6e6',
  measurementId: 'G-RRWMVD6EPE',
}

export default firebaseConfig
