import React, { Fragment, useEffect, useState } from 'react'
import { Row, Col, Button, Divider, Spin } from 'antd'
import '../styles/Home.css'
import UserInput from './UserInput'
import UserOutput from './UserOutput'
import { logEvent } from "firebase/analytics"
import { analytics } from "../firebaseInit"

const defaultAge = 25

export const styleButtonActive = {
  border: '1px solid #4096ff',
}

export default function Home() {
  const [gender, setGender] = useState('M')
  const [age, setAge] = useState(defaultAge)
  const [isShowResult, setIsShowResult] = useState<boolean>(false)
  const [selectedPlan, setSelectedPlan] = useState<string>('bronze_b')
  const [selectedPayment, setSelectedPayment] = useState<number>(1)
  const [spinning, setSpinning] = useState(false);

  const onCountClick = () => {
    logEvent(analytics, 'click_count', {
      insured_age: age,
      insured_gender: gender
    });
    setIsShowResult(true)
    setSpinning(true);
  }
  const onPlanSelected = (planId: string) => {
    logEvent(analytics, 'click_plan', {
      plan_id: planId
    });
    setSelectedPlan(planId)
  }

  const onPaymentSelected = (paymentDiscount: number) => {
    logEvent(analytics, 'click_payment', {
      payment: paymentDiscount
    });
    setSelectedPayment(paymentDiscount)
  }

  const getGenderString = (gender: string) => {
    return gender === 'M' ? 'Pria' : 'Wanita'
  }

  const contactWA = () => {
    logEvent(analytics, 'click_whatsapp')
    let message = `Halo Deva Kumala, Saya tertarik dengan promo premi asuransi plan ${selectedPlan.toUpperCase()} dengan pembayaran ${selectedPayment} bulan untuk tertanggung berjenis kelamin ${getGenderString(gender)} dan berumur ${age} tahun.`
    message = message.replace(' ', '%20')
    const url = `https://wa.me/+6287804600500?text=${message}`
    window.open(url, '_blank')
  }

  useEffect(() => {
    setIsShowResult(false)
  }, [age, gender])

  return (
    <Fragment>
      <Row justify="center">
        <Col xl={10} lg={10} md={20} sm={20} xs={24}>
          <h2 className="center">Cek Harga Premi Asuransi MSIG</h2>
        </Col>
      </Row>
      <UserInput
        gender={gender}
        age={age}
        setGender={setGender}
        setAge={setAge}
        isShowResult={isShowResult}
        onCountClick={onCountClick}
      />
      <Divider className="divider" />
      <Spin spinning={spinning}>
        {isShowResult && (
          <UserOutput
            gender={gender}
            age={age}
            selectedPayment={selectedPayment}
            onPaymentSelected={onPaymentSelected}
            selectedPlan={selectedPlan}
            onPlanSelected={onPlanSelected}
            setSpinning={setSpinning}
          />
        )}

        {isShowResult && (
          <Row justify="center">
            <Col xl={10} lg={10} md={20} sm={20} xs={24} className="center">
              <Button id="buttonCount" onClick={contactWA}>
                Ambil premi!
              </Button>
            </Col>
          </Row>
        )}
      </Spin>
    </Fragment>
  )
}
