import React, { Fragment, useEffect, useState } from 'react'
import { Row, Col, Radio, Button, Popover } from 'antd'
import '../styles/UserOutput.css'
import { styleButtonActive } from './Home'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { InsurancePlan, getAllPlan } from '../data/plan'
import {
  PaymentMethod,
  findPaymentCutterByMonth,
  paymentMethod,
} from '../data/payment'
import { getCostByGenderAndAge } from '../data/cost'

export interface UserOutputProps {
  gender: string
  age: number
  selectedPlan: string
  selectedPayment: number
  onPlanSelected: any
  onPaymentSelected: any
  setSpinning: any
}

export default function UserOutput({
  gender,
  age,
  selectedPayment,
  onPaymentSelected,
  selectedPlan,
  onPlanSelected,
  setSpinning
}: UserOutputProps) {
  const [insuranceCost, setInsuranceCost] = useState<any>()
  const [insurancePlan, setInsurancePlan] = useState<InsurancePlan[]>([])

  useEffect(() => {
    async function getCostData() {
      const costData: any = await getCostByGenderAndAge(age, gender)
      setInsuranceCost(costData)
      setSpinning(false)
    }
    if (insuranceCost === undefined) getCostData()
  }, [insuranceCost, age, gender, setSpinning])

  useEffect(() => {
    async function getInsurancePlanData() {
      const allPlan = await getAllPlan()
      setInsurancePlan(allPlan)
    }
    if (insurancePlan.length === 0) getInsurancePlanData()
  }, [insurancePlan])

  return (
    <Fragment>
      <Row justify="center">
        <Col>
          <Radio.Group
            defaultValue={1}
            size="small"
            value={selectedPayment}
            onChange={(e: any) => {
              onPaymentSelected(e.target.value)
            }}
          >
            {paymentMethod.map((method: PaymentMethod) => {
              return (
                <Radio.Button
                  key={method.totalMonth}
                  value={method.totalMonth}
                  checked={selectedPayment === method.totalMonth}
                >
                  {method.title}
                </Radio.Button>
              )
            })}
          </Radio.Group>
        </Col>
      </Row>
      <Row justify="center" gutter={[0, 10]} id="planList">
        {insurancePlan.map((plan: InsurancePlan) => {
          return (
            <Col
              xl={4}
              lg={4}
              md={6}
              sm={12}
              xs={12}
              className="center"
              key={plan.planId}
            >
              <Button
                className="buttonPlan center"
                key={plan.planId}
                onClick={() => {
                  onPlanSelected(plan.planId)
                }}
                style={selectedPlan === plan.planId ? styleButtonActive : {}}
              >
                <Popover
                  content={plan.description}
                  title={plan.name}
                  placement="top"
                  trigger="click"
                >
                  <QuestionCircleOutlined className="iconDescription" />
                </Popover>
                <p className="planTitle">{plan.title}</p>
                <p className="planBedDescription">{`${plan.bed} Kasur / Kamar`}</p>
                <p className="planSubtitle">{plan.subtitle}</p>
                <p className="planPriceUnit">{plan.priceUnit}</p>
                <p className="planCost">
                  {(
                    (insuranceCost[plan.planId] *
                      findPaymentCutterByMonth(selectedPayment)) /
                    100
                  ).toLocaleString()}
                </p>
                <p className="planPeriodUnit">/{selectedPayment} {plan.periodUnit}</p>
                <Row justify={'center'} className="planLimit">
                  <Col>
                    <p className="planLimitTitle">Limit per tahun 
                      <span className="planLimitBoosterDesc"> +Booster</span>
                    </p>
                    <span className="planLimitCoverage">
                      {plan.limitCoverage}M
                    </span>
                    <span className="planLimitBooster">
                      +{plan.limitBooster}M
                    </span>
                  </Col>
                </Row>
              </Button>
            </Col>
          )
        })}
      </Row>
    </Fragment>
  )
}
