import { collection, query, where, getDocs } from 'firebase/firestore'
import { db } from '../firebaseInit'

export class InsuranceCost {
  bronze_b: number
  bronze_d: number
  silver_b: number
  gold_a: number

  constructor(snapshot: any) {
    this.bronze_b = snapshot.bronze_b
    this.bronze_d = snapshot.bronze_d
    this.silver_b = snapshot.silver_b
    this.gold_a = snapshot.gold_a
  }
}

export async function getAllCost() {
  const querySnapshot = await getDocs(collection(db, 'insurance_cost'))
  if (querySnapshot.empty) {
    console.log('No such collection!')
    return []
  } else {
    return querySnapshot
  }
}

export async function getCostByGenderAndAge(age: number, gender: string) {
  const q = query(
    collection(db, 'insurance_cost'),
    where('age', 'array-contains', age),
    where('gender', '==', gender)
  )

  const querySnapshot = await getDocs(q)
  if (querySnapshot.empty) {
    console.log('No such collection!')
    return []
  } else {
    let data
    querySnapshot.forEach((doc) => {
      data = doc.data()
    })
    return new InsuranceCost(data)
  }
}
