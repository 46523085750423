import { createBrowserRouter, RouterProvider, Outlet } from 'react-router-dom'
import { Layout } from 'antd'
import './styles/App.css'
import Logo from './assets/icons/logo.svg'
import LogoMSIG from './assets/icons/logo_msig.webp'
import Page404 from './components/pages/Page404'
import Home from './routes/Home'
import useWindowDimensions from './components/util/WindowDimensions'

const { Header, Footer, Content } = Layout

const router = createBrowserRouter([
  {
    path: '/',
    element: <MainLayout />,
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        path: '*',
        element: <Page404 />,
      },
    ],
  },
])

function MainLayout() {
  const { height } = useWindowDimensions()
  return (
    <Layout>
      <Header id={'header'}>
        <div>
          <img id={'logoDeva'} src={Logo} alt={'Deva Kumala Asuransi'} />
          <img id={'logoMSIG'} src={LogoMSIG} alt={'Logo MSIG'} />
        </div>
      </Header>
      <Content id={'content'} style={{ minHeight: height - 135 + 'px' }}>
        <Outlet />
      </Content>
      <Footer id={'footer'}>Copyright &copy; 2024 Byvidé.com</Footer>
    </Layout>
  )
}

export default function App() {
  return <RouterProvider router={router} fallbackElement={<p>Menunggu...</p>} />
}
