export interface PaymentMethod {
  totalMonth: number
  cutter: number
  discount?: number
  title: string
}

export const paymentMethod: PaymentMethod[] = [
  { totalMonth: 1, cutter: 10, title: '1 Bulan' },
  { totalMonth: 3, cutter: 27, discount: 10, title: '3 Bulan' },
  { totalMonth: 6, cutter: 52.5, discount: 12.5, title: '6 Bulan' },
  { totalMonth: 12, cutter: 100, discount: 16.7, title: '1 Tahun' },
]

export const findPaymentCutterByMonth = (month: number) => {
  let cutter: number = 10
  for (let i = 0; i < paymentMethod.length; i++) {
    if (paymentMethod[i].totalMonth === month) {
      cutter = paymentMethod[i].cutter
      break
    } else {
      cutter = 10
    }
  }
  return cutter
}
